// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-consulta-js": () => import("./../src/pages/consulta.js" /* webpackChunkName: "component---src-pages-consulta-js" */),
  "component---src-pages-contacto-js": () => import("./../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-detalle-propiedad-js": () => import("./../src/pages/detalle-propiedad.js" /* webpackChunkName: "component---src-pages-detalle-propiedad-js" */),
  "component---src-pages-entreguenos-su-propiedad-js": () => import("./../src/pages/entreguenos-su-propiedad.js" /* webpackChunkName: "component---src-pages-entreguenos-su-propiedad-js" */),
  "component---src-pages-equipo-js": () => import("./../src/pages/equipo.js" /* webpackChunkName: "component---src-pages-equipo-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-orden-visita-js": () => import("./../src/pages/orden-visita.js" /* webpackChunkName: "component---src-pages-orden-visita-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-propiedades-en-remate-js": () => import("./../src/pages/propiedades-en-remate.js" /* webpackChunkName: "component---src-pages-propiedades-en-remate-js" */),
  "component---src-pages-propiedades-js": () => import("./../src/pages/propiedades.js" /* webpackChunkName: "component---src-pages-propiedades-js" */),
  "component---src-pages-quienes-somos-js": () => import("./../src/pages/quienes-somos.js" /* webpackChunkName: "component---src-pages-quienes-somos-js" */),
  "component---src-pages-trabaje-con-nosotros-js": () => import("./../src/pages/trabaje-con-nosotros.js" /* webpackChunkName: "component---src-pages-trabaje-con-nosotros-js" */)
}

